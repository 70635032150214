import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from 'src/components/common/Layout';
import { MetaData } from 'src/components/common/Meta';
import ServicesContent from 'src/components/ServicesContent';

interface IServicesPage {
  location: {
    pathname: string;
  };
  data: {
    ghostPage: {
      codeinjection_styles: {};
      title: string;
      html: string;
      feature_image: string;
    };
  };
  pageContext: {
    slug: string;
  };
}

const Services = ({
  data,
  location,
  pageContext,
}: IServicesPage): ReactElement => {
  const page = data.ghostPage;
  return (
    <>
      <MetaData data={data} location={location} type="article" />
      <Helmet>
        <style type="text/css">{`${page.codeinjection_styles}`}</style>
      </Helmet>
      <Layout>
        <ServicesContent
          title={page.title}
          description={page.html}
          image={page.feature_image}
        />
      </Layout>
    </>
  );
};

export default Services;

export const servicesQuery = graphql`
  query ServicesQuery($slug: String!) {
    ghostPage(slug: { eq: $slug }) {
      ...GhostPageFields
    }
  }
`;
