import styled from '@emotion/styled';
import Container from 'react-bootstrap/Container';

export const Wrapper = styled(Container)`
  margin-bottom: 2rem;
`;

export const Article = styled.article``;

export const FeatureImage = styled.figure`
  img {
    width: 150px;
    height: auto;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const Title = styled.h2`
  margin-top: -3rem;
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: bold;
  font-size: 28px;
`;

export const Content = styled.section`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 18px;
  img {
    max-width: 100%;
    padding: 1rem;
    height: auto;
  }
  margin-bottom: 8rem;
`;
