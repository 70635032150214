import React, { ReactElement } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  Wrapper,
  Article,
  FeatureImage,
  Header,
  Title,
  Content,
} from './styles';

interface IServicesContent {
  title: string;
  description: string;
  image: string;
}

const ServicesContent = ({
  title,
  description,
  image,
}: IServicesContent): ReactElement => {
  return (
    <Wrapper>
      <Row noGutters>
        <Col>
          <Article>
            <Header>
              {image && (
                <FeatureImage>
                  <img src={image} alt={title} />
                </FeatureImage>
              )}
              <Title>{title}</Title>
            </Header>
            <section>
              {/* The main post content */}
              <Content
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </section>
          </Article>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default ServicesContent;
